import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  getCurrentInstance
} from 'vue'
import { draftItemUseCase } from '@/domain/usecase'
import InlineSvg from 'vue-inline-svg'
import { useStore } from 'vuex'
import { IDraftItem } from '@/utils/interfaces/iDraftItem'
import { debounce } from 'lodash'
import $ from 'jquery'
import QrScanner from '@/views/shared-components/barcode-scanner/QrScanner.vue'
import DialogDetailResiV2 from '@/views/shared-components/dialog/dialog-detail-resi-v2/index.vue'

export default defineComponent({
  name: 'DraftItem',
  components: {
    InlineSvg,
    QrScanner,
    DialogDetailResiV2
  },
  setup() {
    const app = getCurrentInstance()
    const { $enumBase, $icon, $toast } = app!.appContext.config.globalProperties
    const enumBase = $enumBase
    const icon = $icon
    const filterCal = ref()
    const refVideo = ref()
    const store = useStore()
    const inputScanResi = ref<string>('')
    const dataDrafts = ref<IDraftItem[]>([])
    const dateFilter = ref([])
    const isShowDetail = ref(false)
    const draftSelect = ref<IDraftItem | null>(null)
    const showSearch = ref<boolean>(false)
    const inputSearch = ref<string>('')
    const errorSearchMessage = ref('')
    const isDialogCamera = ref(false)
    const searchOptions = ref([
      { name: 'Hp Pengirim', value: 'HpPengirim' },
      { name: 'Hp Penerima', value: 'HpPenerima' },
      { name: 'Nama Pengirim', value: 'NamaPengirim' },
      { name: 'Nama Penerima', value: 'NamaPenerima' },

    ])
    const selectedSearchOption = ref('NamaPengirim')
    const filters = reactive({
      search: '',
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      filterField: [selectedSearchOption.value],
      custom: [['orderBy', '=', 'createdAt desc']]
    })
    const saveFilterDate = async () => {
      console.log('save filter date')
    }
    const clearFilter = () => {
      console.log('clear filter')
    }
    const showCalendar = (evt: any) => {
      filterCal.value.toggle(evt)
    }

    const fetchDataDraft = () => {
      store.dispatch('showLoading')
      // console.log('val', selectedSearchOption?.value)
      draftItemUseCase.getDelivery(filters)
        .then(({ result, error, message }) => {
          if (!error) {
            errorSearchMessage.value = ''
            dataDrafts.value = result.Data
            filters.totalRecords = result.Count
          } else {
            // console.log('error', [result, error, message])
            errorSearchMessage.value = message
          }
          store.dispatch('hideLoading')
        })
    }

    const processScanResi = async (search: string) => {
      if (search) {
        const splitChar = search.split(':')
        if (splitChar.length > 1 && splitChar[1].length === 36) {
          try {
            store.dispatch('showLoading')
            const { error, message } = await draftItemUseCase.createDeliveryDraft(search)
            if (!error) {
              $toast.add({
                severity: 'success', detail: 'Berhasil melakukan scan', group: 'bc', life: 1500
              })
              fetchDataDraft()
            } else {
              $toast.add({
                severity: 'error', detail: message, group: 'bc', life: 3000
              })
            }
            inputScanResi.value = ''
            store.dispatch('hideLoading')
          } catch (err) {
            store.dispatch('hideLoading')
          }
        }
      }
    }

    const onPage = async (evt: any) => {
      filters.pageNumber = await evt.page + 1
      fetchDataDraft()
    }

    const onProcessTrans = (draft: IDraftItem) => {
      draftSelect.value = draft
      setTimeout(() => {
        isShowDetail.value = true
      }, 500)
    }

    onMounted(() => {
      fetchDataDraft()
    })

    // watch(isShowDetail, (val: any, newVal: any) => {
    //   console.log('what val', val)
    // })

    const processSearch = (newVal: string, oldVal: string) => {
      // console.log('searchval', [newVal, oldVal])
      if (newVal !== oldVal && oldVal !== undefined) {
        filters.search = newVal
        fetchDataDraft()
      } else if (oldVal === undefined) {
        fetchDataDraft()
      }
    }

    const onScan = () => {
      isDialogCamera.value = true
    }

    const onDetectScanner = (result: any) => {
      if (result.data) {
        inputScanResi.value = result.data
        isDialogCamera.value = false
      }
    }

    const onChangeSelectedSearchOption = (val: any) => {
      // console.log('val', val)
      selectedSearchOption.value = val.value
      filters.filterField[0] = val.value
      inputSearch.value = ''
      $('#search-bar').children('#input-search').val('')
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('input-search')?.dispatchEvent(new Event('input'))
      // console.log($('#search-bar').children('#input-search').val())
    }

    watch(() => inputScanResi.value, debounce(processScanResi, 500))
    watch(() => inputSearch.value, debounce(processSearch, 300))

    return {
      dateFilter,
      saveFilterDate,
      clearFilter,
      filterCal,
      showCalendar,
      inputScanResi,
      dataDrafts,
      filters,
      onPage,
      isShowDetail,
      onProcessTrans,
      draftSelect,
      showSearch,
      inputSearch,
      enumBase,
      icon,
      onScan,
      refVideo,
      isDialogCamera,
      onDetectScanner,
      searchOptions,
      selectedSearchOption,
      errorSearchMessage,
      onChangeSelectedSearchOption,
      processSearch
    }
  }
})
