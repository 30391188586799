/* eslint-disable global-require */
import {
  ref, getCurrentInstance, computed, onMounted, defineComponent
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default defineComponent({
  setup() {
    const store = useStore()
    const lastReceiptId = computed(() => store.state.deliveryItem.lastReceipt)
    const app = getCurrentInstance()
    const { $swal, $toast, $strInd } = app!.appContext.config.globalProperties
    const route = useRoute() as any
    const router = useRouter()
    const cardOption = ref([{
      title: 'Agent to Agent',
      subTitle: 'Pengirim mendatangi agen asal untuk mengirim, dan penerima akan menerima paket di agen tujuan.',
      assets: require('@/assets/img/AgentTAgent.png'),
      routing: 'agent-to-agent'
    },
    {
      title: 'Agent to Door',
      subTitle: 'Pengirim mendatangi agen asal untuk mengirim, dan paket akan dikirim kan langung ke alamat penerima.',
      assets: require('@/assets/img/AgentTDoor.png'),
      routing: 'agent-to-door'
    },
    {
      title: 'Door to Agent',
      subTitle: 'Agen asal mendatangi pengirim untuk menjemput paket untuk mengirimkan paket menuju agen tujuan.',
      assets: require('@/assets/img/DoorTAgent.png'),
      routing: 'door-to-agent'
    },
    {
      title: 'Door to Door',
      subTitle: 'Agen asal mendatangi pengirim untuk menjeput paket untuk mengirimkan paket menuju alamat penerima.',
      assets: require('@/assets/img/DoorTDoor.png'),
      routing: 'door-to-door'
    }])

    const hasAccess = (data: any) => {
      console.log('route', [route, store])
      if (route.meta.permission.write) {
        router.push({ name: 'transaksi-pengiriman-barang-tambah', params: { type: data.routing } })
        // if (data.routing.split('-', 1)[0] === 'door') {
        //   console.log('aaaa')
        //   $swal.fire({
        //     title: 'Maaf menu tidak tersedia',
        //     text: 'Silahkan akses menu berikut melalui aplikasi mobile',
        //     icon: 'info',
        //     iconColor: '#ff9800',
        //     showCancelButton: false,
        //     confirmButtonColor: '#ff9800',
        //     allowOutsideClick: false,
        //     confirmButtonText: 'OK'
        //   })
        // } else {
        //   // console.log(data.routing.split('-', 1)[0])
        //   router.push({ name: 'transaksi-pengiriman-barang-tambah', params: { type: data.routing } })
        // }
      } else {
        $toast.add({
          severity: 'error', detail: 'Mohon maaf, anda tidak memliki akses', group: 'bc', life: 3000
        })
      }
    }
    const openLastReceipt = (data: any) => {
      const encryptDataUser = encryptDecriptMethods.encrypt(store.getters['appActiveUser/getAppActiveUser']?.id)
      const routeData = router.resolve({ name: 'report', params: { Id: store.state.deliveryItem.lastReceipt, jenisReport: 'resi', idUser: encryptDataUser } })
      window.open(routeData.href, '_blank')
      router.push({ name: 'transaksi-pengiriman-barang' })
    }

    return {
      route,
      hasAccess,
      cardOption,
      openLastReceipt,
      lastReceiptId
    }
  }
})
